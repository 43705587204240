#loading-overlay {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 99999;
  background: rgba(33, 87, 135, 0.5);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 140px;
  height: 140px;
  margin: 0 auto;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  border-radius: 10px;
  left: 0px;
  width: 25px;
  background: rgba(255, 255, 255, 1);
  animation: lds-facebook 1.2s cubic-bezier(0, 0.75, 0.75, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 10px;
  animation-delay: -0.25s;
}
.lds-facebook div:nth-child(2) {
  left: 50px;
  animation-delay: -0.15s;
}
.lds-facebook div:nth-child(3) {
  left: 90px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 5px;
    height: 150px;
    background: #1f96ff;
  }
  50%,
  100% {
    top: 19px;
    height: 75px;
  }
}
