@import '../../shared/variables';



.view {
    .view-dashboard .sales-card {
        .card + .card .card-block {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            border-bottom: 1px solid $md-blue-grey-50;
            svg {
                transform: translate(0, -2px);
            }
        }
        .card + .card .card-block:last-child {
            border: 0;
        }
    }
}


@media only screen and (max-device-width: 500px) {
    .col-6 {
        flex: 0 0 50%;
         flex-grow: 0;
         flex-shrink: 0;
         flex-basis: 100%;
         max-width: 100%;
     }
     .table-responsive .card-body{
        width: 50%;
        padding: 0 0 0 0;
        margin: 0 auto;

    }  
    .react-bootstrap-table table {
        display: table-row-group;
        table-layout: fixed;
    }

}

.notifrow{
    &:hover{
        background-color: rgba(51, 136, 189, 0.3);
    }
    cursor: pointer;
}

$primary: #6569df;
$success: #24d5d8;
$info: #04a1f4;
$warning: #fecd2f;
$danger: #fd3259;

$gradient-primary: linear-gradient(120deg, #b603c1 0%, #7a38e0 100%);
$gradient-success: linear-gradient(120deg, #1dccdf 0%, #1de4bd 100%);
$gradient-info: linear-gradient(120deg, rgb(51, 135, 189) 0%, rgb(0, 16, 85) 100%);
$gradient-warning: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
$gradient-danger: linear-gradient(120deg, #f3301a 0%, #f37138 100%);

.infoCard{
    width: 100%;
    font-size: 18px;

    b{
        float: right;
        font-size: 20px;
    }
}

.topDashPanels{
    
}

.loansPanel {
    margin: 20px 0px;

    .card{

        background: linear-gradient(120deg, #7a38e0, #34185f) !important;//linear-gradient(225deg, $background1, transparent, $background2, transparent, $background3, transparent, $background4) !important;

        background-size: 400% 400% !important;

        // Background Anim
        -webkit-animation: BackgroundGradientAnimMain 10s ease infinite !important;
        -moz-animation: BackgroundGradientAnimMain 10s ease infinite !important;
        animation: BackgroundGradientAnimMain 10s ease infinite !important;

        border-radius: 5px;
        border: none !important;
        margin: 10px;

        .card-body{
            border: none !important;
        }
    }
}

.fundsPanel {
    margin: 20px 0px;
    
    .card{
        background: linear-gradient(120deg, #1ddf85, #0b5231) !important;//linear-gradient(225deg, $background1, transparent, $background2, transparent, $background3, transparent, $background4) !important;

        background-size: 400% 400% !important;

        // Background Anim
        -webkit-animation: BackgroundGradientAnimMain 10s ease infinite !important;
        -moz-animation: BackgroundGradientAnimMain 10s ease infinite !important;
        animation: BackgroundGradientAnimMain 10s ease infinite !important;
        
        border-radius: 5px;
        border: none !important;
        margin: 10px;

        .card-body{
            border: none !important;
        }
    }
}


@-webkit-keyframes BackgroundGradientAnimMain {
    0%{background-position:0% 50%}
    50%{background-position:100% 51%}
    100%{background-position:0% 50%}
  }
  @-moz-keyframes BackgroundGradientAnimMain {
    0%{background-position:0% 50%}
    50%{background-position:100% 51%}
    100%{background-position:0% 50%}
  }
  @keyframes BackgroundGradientAnimMain { 
    0%{background-position:0% 50%}
    50%{background-position:100% 51%}
    100%{background-position:0% 50%}
  }

