@import './shared/variables';

.app-wrapper {
    display: block;
    min-height: 100vh;

    background-color: $brand-background;
    overflow: hidden;
}
.cursor {
    cursor: pointer;
}
.accordion__heading{
    font-size: 17px;
    color: #515365;
    font-weight: normal;
    line-height: 1.5;
    cursor: pointer;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 8px;
    padding-top: 20px;
}

.content-container {
    width: 100%;
    transform: translate(0,0);
    position: relative;
    @media(min-width: 992px) {
        &.full {
            width: calc(100% - .75em);
            transform: translate(.75em, 0);
        }
        width: calc(100% - 20em);
        transform: translate(20em, 0);
        // always hide on desktop
        .menu-dropshadow {display: none!important;}
    }
    // show on only menu show + mobile view (for hiding navigation)
    .menu-dropshadow {
        display: none;
        position: absolute;
        top: 0; bottom: 0; right: 0; left: 0;
        background: rgba(0,0,0,.2);
        z-index: $zindex-dropdown-backdrop;
    }

    &.mobile{
        transition: 0.75s;
        left: 0px;
        top: 0px;
    }
}

.content-container.full.mobile{
    left: 300px;
    top: 40px;
    position: fixed;

    -webkit-box-shadow: -6px -6px 35px 0px rgba(70, 117, 184, 0.5);
    -moz-box-shadow:    -6px -6px 35px 0px rgba(70, 117, 184, 0.5);
    box-shadow:         -6px -6px 35px 0px rgba(70, 117, 184, 0.5);
}

.site-head {
    height: 62px;
    background: $view-header-bg;
    padding: 1.2rem;
}

.view {
    background-color: $body-bg;
    min-height: 90vh;

    .view-header, .view-content { padding: 1em 1.2rem;}
    .view-header {
        background: $view-header-bg;
        margin-top: -2px;
        padding-bottom: 4.4rem;
    }
    .view-content {
        margin-top: -4.2rem;
    }
    .view-header .breadcrumb-item + .breadcrumb-item::before {
        color: #fff;
    }
    // for full pages like signin, 404 etc.
    .view-content.view-pages {
       padding: 4rem;
       margin: 0;
       min-height: 100vh;
    }
}

@media (min-width: 768px) {
    .site-head,
    .view .view-header,
    .view .view-content { padding: 1.1em 2rem;}
    .view .view-header {padding-bottom: 4.4rem;}
    .view-header header {
        .title {font-size: 1.45rem;}
        .subtitle {font-size: $font-size-root}
    }
}
  
.nav-tabs .nav-link{
    border-radius: 3px;
    opacity: 0.75;
    font-weight: 500;
    background-color: transparent;
    color: #515365;
    border: 1px solid transparent;
    padding: 5px 15px;
}
.nav-tabs .nav-link.active {
    color: #ffffff;
    background-color: #0A5787;
    border-color: #0A5787;
}
.dzu-inputLabelWithFiles {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    padding: 0 14px;
    min-height: 32px;
    background-color: #E6E6E6;
    color: #2484FF;
    border: none;
    font-family: 'Helvetica', sans-serif;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    margin: 10px auto!important;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    cursor: pointer;
}
.absolute_center{
    position: absolute;
    left: 50%;
    transform: translate(-50%);
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

.pagination li {
  margin: 0 5px;
  padding: 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 35px;
  height: 35px;
  transition: background-color 0.3s ease;
  text-align: center;
}

.pagination li a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 8px 12px;
  color: #007bff;
  text-decoration: none;
}

.pagination li:hover {
  background-color: #f0f0f0;
}

.pagination .active {
  background-color: #007bff;
  border-color: #007bff;
  &:hover {
    background-color: #007bff;
  }
}

.pagination .active a {
  color: white;
}

.pagination .disabled {
  color: #ccc;
  cursor: not-allowed;
}

.pagination .break {
  cursor: default;
}

/* Smaller screens */
@media (max-width: 768px) {
  .pagination li {
      min-width: 30px;
      height: 30px;
  }
}

@media (max-width: 480px) {
  .pagination li {
      min-width: 28px;
      height: 28px;
  }

  .prev, .next {
      font-size: 12px;
  }

  .page {
      font-size: 12px;
  }

  .break {
      display: none;
  }
}

  
  
  
    
  
  
  