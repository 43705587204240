// ======== Theme Blue
// ------- site-nav
// ----------------
$site-nav-bg:                       $md-white;
$site-nav-toggle-color:             #215787;
$site-nav-head-color:               $md-grey-900;
$site-nav-head-border-color:        $md-blue-50;
$site-nav-list-color:               lighten($md-white, 8%);
$site-nav-list-hover-color:         #215787;

// ------ view-header
// ------------------
$view-header-bg:                    linear-gradient(120deg, rgb(51, 135, 189) 0%, rgb(0, 16, 85) 100%);


/// MORE COMING SOON 


/// ####### END #######
