@import '../../shared/variables';



.view {
    .view-balance .sales-card {
        .card + .card .card-block {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            border-bottom: 1px solid $md-blue-grey-50;
            svg {
                transform: translate(0, -2px);
            }
        }
        .card + .card .card-block:last-child {
            border: 0;
        }
    }

}

.tableRow{
    cursor: pointer;
}
.tableCol{
    font-weight: bold;
    &:hover{
        background-color: rgba(51, 136, 189, 0.3);
    }
}
.reactable-filter-input{
    float: right;
}
.profileSwitch{
    background: hsla(208, 59%, 45%, 0.2);
    &.on{
        background: #2f79b9;
    }
}

.detailRow{
    padding: 5px 0px;
}

@media only screen and (max-device-width: 515px) {
    .col-4 {
        flex: 0 0 50%;
         flex-grow: 0;
         flex-shrink: 0;
         flex-basis: 100%;
         max-width: 100%;
     }
     .col-8 {
        flex: 0 0 50%;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 100%;
        max-width: 100%;

     }
     .table-responsive .card-body{
        width: 100%;
        padding: 0 0 0 0;
    }  
    .react-bootstrap-table table {
        display: table-row-group;
        table-layout: fixed;
    }
    .table {
        width: 100%;
        margin-top: 20px;
        color: #8a8a8a;
        background-color: transparent;
        display: block;
        overflow-y: auto;
        white-space: nowrap;
        
    }
}