@media only screen and (max-device-width: 414px) {
    .table-responsive .card-body{
        width: 100%;
        padding: 0 0 0 0;
    }  
    .react-bootstrap-table table {
        display: table-row-group;
        table-layout: fixed;
    }
}



@media only screen and (max-device-width: 515px) {
    .table-responsive .card-body{
        width: 100%;
        padding: 0 0 0 0;
    }  
}



    
  