@import '../../../shared/variables';

.loansScroll{
    width: 100%;
    // background-color: rgb(255, 0, 0);
}

.horizontal-menu{
    height: 150px;
    border-radius: 5px;

    background-color: $brand-primary-trans;
}

.loanScrollItem{
    width: 200px;
    height: 100%;

    // background-color: aqua;
}

.arrow-next, .arrow-prev {
    font-size: 24px;
    color: white;
}

.menu-item {
    height: 130px;

    padding: 40px;
    margin: 10px;
    user-select: none;
    cursor: pointer;
    border: none;

    text-align: center;
  }

.menu-item-wrapper {
    height: 150px;
  }

.menu-item-wrapper.active {
    
}
.datePicker{
    color: #8a8a8a;
    border-radius: 5px;
    box-shadow: none;
    border: 1px solid #e4e5e7;
    font-size: 14px;
    line-height: 2.2;
}

.menu-item.active {
    border: 1px $brand-secondary-trans solid;
    border-radius: 5px;

    background-color: $brand-secondary-trans;
  }

.scroll-menu-arrow {
    padding: 20px 0px;
    cursor: pointer;
  }

  @media only screen and (max-width: 414px) {
    .col-3 {
        flex: 0 0 50%;
         flex-grow: 0;
         flex-shrink: 0;
         flex-basis: 100%;
         max-width: 100%;
     }
     .col-9 {
      flex: 0 0 50%;
       flex-grow: 0;
       flex-shrink: 0;
       flex-basis: 100%;
       max-width: 100%;
   }
}

.card-body {
    overflow-x: auto; // Allow scrolling on the x-axis if needed
    padding: 16px;

    .details-container {
        min-width: 370px; // Define the minimum width for scrollable content
    }

    .details-list {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
    
    .detail-item:first-child {
        border-bottom: 1px solid #ccc; // Add border only to the first element
        padding-bottom: 10px; // Ensure spacing for the border
    }

    .detail-item {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
    }

    dt {
        flex: 0 0 200px; // Set fixed width for labels
        font-weight: 500;
        color: #3f3f3f;
    }

    dd {
        flex: 1;
        text-align: right;
        margin: 0;
    }
}
