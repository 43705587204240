@import '../../shared/variables';

.horizontal-menu{
    background-color: $brand-primary;
    height: 40px;
    padding: 0px 20px;
}

.menu-item {
    width: 120px; 
    height: 40px;

    color: $body-bg;

    &.active{
        border-bottom: 2px solid $brand-secondary;
    }
}