@import '../../shared/variables';

.header {
  color: #a5afd7;
  font-weight:400;
  font-size: 1.2rem;
  margin: 1.2rem 0 0 1.2rem;
  padding: 1.6rem 0;
  padding-left: 0.4rem;
}

.credits {
  color: #a5c0d7;
  font-weight:1.2rem;
}
