.loanProgress{
    margin: 10px 0px;
    
    .text-center{
        font-size: 18px;
        padding-bottom: 5px;
    }
}

.pbar{
    height: 25px !important;

    .progress-bar{
        font-weight: bold;
        font-size: 11px;
    }
}

.bg-b1{
    background-color: #2f79b9 !important;
}
.bg-b2{
    background-color: #40a6ff !important;
}
.bg-b3{
    background-color: #173d5e !important;
}
.bg-b4{
    background-color: #0e5fa7 !important;
}
.bg-b5{
    background-color: #128af3 !important;
}
.bg-b6{
    background-color: #406383 !important;
}

.invalid{
    opacity: 0.4 !important;
    color: #000 !important;
}

@media (max-width: 1200px) {
    .pbar{
        .progress-bar{
            font-weight: bold;
            font-size: 8px;
        }
    }
}
@media only screen and (max-device-width: 414px) {
    .table-responsive .card-body{
        width: 100%;
        padding: 0 0 0 0;
    }  
    .react-bootstrap-table table {
        display: table-row-group;
        table-layout: fixed;
    }
}