@import './md-colors';
@import './themes';

// bootstrap override
// ------------------

$brand-primary:                 #215787;
$brand-secondary:               #041a5e;
$brand-success:                 #24d5d8;
$brand-info:                    $md-light-blue-a700;
$brand-warning:                 $md-amber-a700;
$brand-danger:                  darken($md-red-a200, 10%);
$brand-background:              linear-gradient(120deg, rgb(51, 135, 189) 0%, rgb(0, 16, 85) 100%);

$brand-primary-trans:          rgba(33, 87, 135, 0.75);
$brand-secondary-trans:        rgba(4, 27, 94, 0.5);

$brand-primary-dark:            darken($brand-primary, 25%);
$brand-secondary-dark:          darken($brand-secondary, 25%);


// ----- body$
$body-bg:                       #EEF3F8; //$md-white;
$body-color:                    #8a8a8a;
$body-h-color:                  #313131;
$body-color-w:                  #8a8a8a;
$body-h-color-w:                #d1d1d1;
$inverse-bg:                    $md-grey-900;

// ---- grids
$grid-gutter-width-base:        20px;

// ------ Typograpy
$font-family-sans-serif:      'Muli', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-size-root:                14px;
$border-radius:                 .15rem;
$border-radius-sm:              .1rem;
$border-radius-lg:              .24rem;
$headings-font-weight:          700;

$small-font-size:               76%;


// ------ Buttons
$btn-primary-color:             $md-white;
$btn-primary-bg:                $brand-primary;
$btn-primary-border:            $btn-primary-bg;

$btn-secondary-border:          darken($brand-secondary, 3%);
$btn-secondary-color:           $md-white;
$btn-secondary-bg:              $brand-secondary;

// ------- dropdowns
$dropdown-border-color:         rgba($md-black, .1);
$dropdown-link-hover-bg:        lighten($md-blue-grey-50, 2%);

// ------ Links
$link-hover-decoration:         none;

// ------ states
$state-success-text:            $md-green-900;
$state-success-bg:              $md-green-a100;

$state-info-text:               $md-blue-900;
$state-info-bg:                 $md-light-blue-a100;

$state-warning-text:            $md-orange-900;
$state-warning-bg:              $md-orange-100;

$state-danger-text:             $md-red-900;
$state-danger-bg:               $md-red-100;

// ------- breadcrumbs
$breadcrumb-bg:                 $md-grey-100;

// ------- tags
$tag-font-weight:               normal;
$tag-font-size:                 68%;
$tag-padding-x:                 .3em;
$tag-padding-y:                 .32em;

// -------- badge
$badge-font-size:               65%;

// -------- pagination
$pagination-padding-x:                .95rem;
$pagination-padding-y:                .4rem;
$pagination-padding-x-sm:             .75rem;
$pagination-padding-y-sm:             .26rem;
$pagination-padding-x-lg:             1.5rem;
$pagination-padding-y-lg:             .7rem;
$pagination-color:                   $md-grey-800;

// ------- Forms
$input-padding-x:                .8rem;
$input-padding-y:                .65rem;
$input-line-height:              1.25;

$input-border-focus:             $md-blue-a100;
$input-group-addon-bg:           $md-blue-grey-50;
$input-group-addon-border-color: $md-blue-grey-100;

// ------ Cards
$card-border-width:             1px;
$card-border-color:             $md-blue-grey-50;
$card-spacer-x:                 1.6rem;
$card-spacer-y:                 1rem;
$card-img-overlay-padding:      1.4rem;
$card-columns-gap:              1.3rem;
$card-cap-bg:                   $md-grey-50;

// ------- Tables
$table-cell-padding:            .8rem;
$table-sm-cell-padding:         .35rem;

$table-bg:                      transparent;

$table-inverse-bg:              $md-blue-grey-900;
$table-inverse-color:           $md-white;

$table-bg-accent:               rgba($md-black,.05);
$table-bg-hover:                rgba($md-black,.075);
$table-bg-active:               $table-bg-hover;

$table-head-bg:                 $md-grey-100;
$table-head-color:              $md-grey-800;

$table-border-color:            $md-grey-200;


// --------- Breadcrumbs
$breadcrumb-bg:                 transparent;
$breadcrumb-padding-y:          .3rem;
$breadcrumb-padding-x:          .1rem;
$breadcrumb-item-padding:       .5rem;
$breadcrumb-divider-color:      $body-color;
$breadcrumb-active-color:       $body-color;
$breadcrumb-divider:            "→";

// ------ Z-Index
$zindex-dropdown-backdrop:  990 !default;
$zindex-navbar:            1000 !default;
$zindex-dropdown:          1000 !default;
$zindex-popover:           1060 !default;
$zindex-tooltip:           1070 !default;
$zindex-navbar-fixed:      1030 !default;
$zindex-navbar-sticky:     1030 !default;
$zindex-modal-bg:          1040 !default;
$zindex-modal:             1050 !default;
